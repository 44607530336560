import React from 'react'
import { ActionButton, Divider, Stack } from '@te-digi/styleguide'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'

import { PAGES } from '../pages'

const PageList = () => {
  const location = useLocation()
  const currentPage = PAGES.find(
    page =>
      page.to === location.pathname || `/latest${page.to}` === location.pathname
  )

  if (!currentPage?.children) {
    return null
  }

  return (
    <Stack gap="lg">
      {currentPage.children.map(child => {
        const actionButtonProps = child.external
          ? {
              href: child.to
            }
          : {
              to: child.to
            }

        return child.title === '-' ? (
          <Divider key={child.title} />
        ) : (
          <ActionButton
            as={child.external ? 'a' : Link}
            description={child.description}
            key={child.title}
            {...actionButtonProps}
          >
            {child.title}
          </ActionButton>
        )
      })}
    </Stack>
  )
}

export { PageList }
